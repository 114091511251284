import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { AlertsComponent } from '../shared/alerts/alerts.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  form: UntypedFormGroup;

  @ViewChild('alerts') alerts: AlertsComponent;

  constructor(private fb: UntypedFormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  login() {
    this.alerts.clear();
    this.authService.login(this.form.value.username, this.form.value.password).subscribe((data) => {
      this.router.navigate(['/ticketsale']);
    }, (error) => {
      console.error(error)
      this.alerts.addError(error);
    });
  }

}
