import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoucherStatusComponent } from './voucher-status/voucher-status.component';
import { AlertsComponent } from './alerts/alerts.component';
import {TimeAgoPipe} from "./time-ago.pipe";
import {DateFormatPipe} from "./date-format.pipe";
import {LocalTimePipe} from "./local.pipe";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    VoucherStatusComponent,
    AlertsComponent,
    TimeAgoPipe,
    DateFormatPipe,
    LocalTimePipe,
  ],
  exports: [
    VoucherStatusComponent,
    AlertsComponent,
    TimeAgoPipe,
    DateFormatPipe,
    LocalTimePipe,
  ]
})
export class SharedModule { }
