import {Component, OnDestroy, OnInit} from "@angular/core"
import {ApiService} from "../api.service"
import {Tml} from "@mb-tml/shared"

import * as moment from "moment"

@Component({
  selector: "app-ticket-sale",
  templateUrl: "./ticket-sale.component.html",
  styleUrls: ["./ticket-sale.component.scss"]
})
export class TicketSaleComponent implements OnInit, OnDestroy {

  tmlLink: Tml

  tml: any

  interval

  lastRefresh
  nextRefresh

  refreshInterval = 5000;

  constructor(private apiService: ApiService) {
  }

  ngOnInit() {
    this.load(moment().add(this.refreshInterval, "milliseconds"))
    this.interval = setInterval(() => {
      this.load(moment().add(this.refreshInterval, "milliseconds"))
    }, this.refreshInterval)
  }

  ngOnDestroy(): void {
    this.cancel()
  }

  cancel() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  load(nextRefresh: moment.Moment) {
    this.apiService.getTml().subscribe(tml => {
      this.tml = tml
    })
    this.apiService.getTmlLink().subscribe((tmlLink) => {
      this.tmlLink = tmlLink
      this.lastRefresh = moment().format("HH:mm:ss")
      this.nextRefresh = nextRefresh.format("HH:mm:ss")

      if (this.tmlLink && this.tmlLink.tmlLink !== "") {
        this.cancel()
      }
    })
  }
}
