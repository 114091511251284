<app-header title="Account einrichten" subTitle=""></app-header>

<div class="p-3 p-md-5">


  <div class="container">
    <div class="row">
      <div class="col-sm">
        <app-tml-token></app-tml-token>
      </div>
        <!--
      <div class="col-sm">
        <div>
          <h2 class="tmlColor">2. Enter your Vouchers
            <i class="fa fa-check" *ngIf="step > 2"></i>
          </h2>

          <ng-template #addVoucherModal let-c="close" let-d="dismiss">
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Add Voucher</h4>
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <app-create-voucher-form [afterCreate]="c" (created)="onVoucherCreated($event)"></app-create-voucher-form>
            </div>
          </ng-template>

          <div class="card mb-2">
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <div class="col-sm centerInBox">
                      <div class="d-flex flex-column text-center">

                          <div class="p-2"><h2>Available</h2></div>

                          <div class="p-2 ">
                              <h3>Total</h3>
                            </div>

                            <div class="p-2 ">
                                <h3>Not yet confirmed</h3>
                              </div>
                        </div>
                  </div>
                  <div class="col-sm centerInBox">

                      <div class="d-flex flex-column text-center">
                          <div class="p-2 text-success">
                              <h2>{{voucherAmounts.confirmed}} €</h2>
                            </div>
                          <div class="p-2 text-primary"><h3>{{voucherAmounts.total}} €</h3></div>
                          <div class="p-2 text-warning"><h3>{{voucherAmounts.notYetConfirmed}} €</h3></div>
                        </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="card mb-2">
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <button class="btn btn-lg btn-outline-success w-100" (click)="open(addVoucherModal)">+ Voucher</button>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap vouchers">
            <div *ngFor="let voucher of vouchers" class="voucher w-100 mb-2">
              <div class="card">
                <div class="card-body">
                  <div class="container">
                    <dl class="row">
                      <dt class="col-sm-3">Status</dt>
                      <dd class="col-sm-9">
                        <app-voucher-status [status]="voucher.status"></app-voucher-status>
                      </dd>
                      <dt class="col-sm-3">Value</dt>
                      <dd class="col-sm-9">
                        {{voucher.value}} €
                      </dd>
                      <dt class="col-sm-3">Code</dt>
                      <dd class="col-sm-9">
                        {{voucher.code}}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>



            </div>
          </div>

        </div>
      </div>

        -->
    </div>
  </div>







</div>
