import { Component, OnInit, Input } from "@angular/core"
import { VoucherStatus } from "@mb-tml/shared"

@Component({
  selector: "app-voucher-status",
  templateUrl: "./voucher-status.component.html",
  styleUrls: ["./voucher-status.component.scss"]
})
export class VoucherStatusComponent implements OnInit {

  @Input() status: number

  statusName = ""
  statusClass = ""

  constructor() { }

  ngOnInit() {
    this.statusName = this.getStatusName()
    this.statusClass = this.getClass()
  }

  getStatusName(): string {
    switch (this.status) {
      case VoucherStatus.VERIFIED:
        return "Verified"
      case VoucherStatus.UNKNOWN:
        return "?"
      case VoucherStatus.INVALID:
        return "Invalid"
      case VoucherStatus.NOT_VERIFIED:
        return "Not verified yet"
    }
  }

  getClass(): string {
    switch (this.status) {
      case VoucherStatus.VERIFIED:
        return "verified"
      case VoucherStatus.UNKNOWN:
        return "unknown"
      case VoucherStatus.INVALID:
        return "invalid"
      case VoucherStatus.NOT_VERIFIED:
        return "not-verified"
    }
  }

}
