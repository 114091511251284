import { Component, OnInit } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap"
import { ApiService } from "../api.service"
import { IVoucher, VoucherStatus } from "@mb-tml/shared"

@Component({
  selector: "app-tml",
  templateUrl: "./tml.component.html",
  styleUrls: ["./tml.component.scss"]
})
export class TmlComponent implements OnInit {

  constructor(private modalService: NgbModal, private apiService: ApiService) { }

  closeResult: string

  voucherAmounts = {
    confirmed: 0,
    notYetConfirmed: 0,
    total: 0
  }

  vouchers: IVoucher[] = []

  loadVouchers() {
    this.apiService.getVouchers().subscribe((data) => {
      this.vouchers = data
      this.calculateVoucherAmounts()
    })
  }

  calculateVoucherAmounts() {
    this.voucherAmounts.total = this.vouchers.filter((voucher) => {
      return voucher.status === VoucherStatus.VERIFIED || voucher.status === VoucherStatus.NOT_VERIFIED
    }).map((voucher) => {
      return voucher.value
    }).reduce(this.addValues, 0)

    this.voucherAmounts.confirmed = this.vouchers.filter((voucher) => {
      return voucher.status === VoucherStatus.VERIFIED
    }).map((voucher) => {
      return voucher.value
    }).reduce(this.addValues, 0)

    this.voucherAmounts.notYetConfirmed = this.vouchers.filter((voucher) => {
      return voucher.status === VoucherStatus.NOT_VERIFIED
    }).map((voucher) => {
      return voucher.value
    }).reduce(this.addValues, 0)
  }

  addValues(a: number, b: number) {
    return a + b
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: "modal-basic-title"}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
    })
  }

  private getDismissReason(reason: any): string {
    /*
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }*/
    return ""
  }

  ngOnInit() {
    this.loadVouchers()
  }

  onVoucherCreated(event) {
    this.loadVouchers()
  }
}
