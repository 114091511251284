import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"

import { HomeComponent } from "./home/home.component"
import { LoginComponent } from "./login/login.component"
import { TmlComponent } from "./tml/tml.component"
import { RegisterComponent } from "./register/register.component"
import { AuthGuardService } from "./auth-guard.service"
import { TicketSaleComponent } from "./ticket-sale/ticket-sale.component"

const appRoutes: Routes = [
    {
        path: "login",
        component: LoginComponent,
        canActivate: [],
        data: {
            loggedIn: false
        }
    },
    {
        path: "setup",
        component: TmlComponent,
        canActivate: [AuthGuardService],
        data: {
            loggedIn: true
        }
    },
    {
        path: "ticketsale",
        component: TicketSaleComponent,
        canActivate: [AuthGuardService],
        data: {
            loggedIn: true
        }
    },
    { path: "home", component: HomeComponent },
    { path: "register", component: RegisterComponent },
    {
        path: "",
        redirectTo: "/home",
        pathMatch: "full"
    },
    {
        path: "admin",
        loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule)
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: true } // <-- debugging purposes only
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
