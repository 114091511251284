import { BrowserModule } from "@angular/platform-browser"
import { NgModule } from "@angular/core"

import { AppComponent } from "./app.component"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { LoginComponent } from "./login/login.component"
import { HomeComponent } from "./home/home.component"
import { TmlComponent } from "./tml/tml.component"
import { CreateVoucherFormComponent } from "./create-voucher-form/create-voucher-form.component"

import { HttpClientModule } from "@angular/common/http"
import { RegisterComponent } from "./register/register.component"
import { HeaderComponent } from "./header/header.component"
import { LoginFormComponent } from "./login-form/login-form.component"
import { AuthService } from "./auth.service"
import { TicketSaleComponent } from "./ticket-sale/ticket-sale.component"
import { TmlTokenComponent } from "./tml-token/tml-token.component"
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCircleNotch,
  faCheckCircle,
  faCog,
  faPlay,
  faStop,
  faInfo,
  faSync,
  faTrash,
  faCloudUploadAlt,
  faHistory,
  faCopy,
  faEdit, faFileEdit
} from "@fortawesome/free-solid-svg-icons"
import { AppRoutingModule } from "./app-routing.module"
import { SharedModule } from "./shared/shared.module"

import { HTTP_INTERCEPTORS } from "@angular/common/http"
import { JwtInterceptor } from "./jwt.interceptor";
import {LoadingInterceptor} from "./shared/loading-interceptor";

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        TmlComponent,
        CreateVoucherFormComponent,
        RegisterComponent,
        HeaderComponent,
        LoginFormComponent,
        TicketSaleComponent,
        TmlTokenComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FontAwesomeModule,
        AppRoutingModule,
        SharedModule,
    ],
    providers: [AuthService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true
      }],
    bootstrap: [AppComponent]
})
export class AppModule {

  constructor(library: FaIconLibrary) {
    library.addIcons(faCircleNotch, faCheckCircle, faCog, faPlay, faStop, faInfo, faSync, faTrash, faCloudUploadAlt, faHistory, faCopy, faEdit, faFileEdit)
  }

}
