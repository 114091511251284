import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import {LoadingService} from "./shared/loading.service";
import {delay} from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  loading = false;

  constructor(public authService: AuthService, private router: Router, private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.loadingSub.pipe(delay(0)).subscribe(loading => {
      this.loading = loading
    })
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['home']);
  }
}
