import {Injectable} from "@angular/core"
import {Observable, of, throwError} from "rxjs"
import {catchError, map} from "rxjs/operators"
import {HttpClient, HttpErrorResponse} from "@angular/common/http"
import {ApiError} from "./api-error.interface"
import {TokenService} from "./token.service"
import {
  ITmlWorker,
  ITicketLink,
  IVoucher,
  Tml,
  TokenResponse,
  User,
  ITmlConfig,
  ITmlDataEvent,
  TMLDataQueueEvent,
  ICreateTicketLinkDTO,
  ICreateUserDTO
} from "@mb-tml/shared"
import {TmlSaleSettingsResponse} from "@mb-tml/shared/dist/tml-sale-settings-response.interface";
import {TmlSaleSettings} from "@mb-tml/shared/dist/tml-sale-settings.interface";

@Injectable({
  providedIn: "root"
})
export class ApiService {

  constructor(private http: HttpClient, private tokenService: TokenService) {
  }

  baseUrl = "/api/"

  getUrl(path): string {
    return this.baseUrl + path
  }

  getToken() {
    return this.tokenService.getToken()
  }

  private handleError(error: HttpErrorResponse) {
    const apiError: ApiError = {}

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      apiError.code = 400
      apiError.message = error.error.message
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      apiError.code = error.error?.statusCode ?? (error as any).statusCode
      apiError.message = error.error?.message ?? error.error?.error ?? (error as any).message
    }

    // Return an observable with a user-facing error message.

    return throwError(() => {
      return new Error(apiError.message)
    })
  }

  makeAuthenticatedGet<T>(url: string): Observable<T> {
    return this.http.get<T>(url, {
      headers: this.getAuthorizationHeaders()
    })
  }

  makeAuthenticatedPost<T>(url: string, body: any): Observable<T> {
    return this.makePost<T>(url, body, true)
  }

  makeAuthenticatedDelete<T>(url: string): Observable<T> {
    return this.makePostOrPut<T>(url, {}, true, "DELETE")
  }

  makeAuthenticatedPatch<T>(url: string, body: any): Observable<T> {
    return this.makePostOrPut<T>(url, body, true, "PATCH")
  }

  makeUnauthenticatedPost<T>(url: string, body: any): Observable<T> {
    return this.makePost<T>(url, body, false)
  }

  getAuthorizationHeaders(): any {
    return {
      // tslint:disable-next-line:max-line-length
      Authorization: "Bearer " + this.tokenService.getToken()
    }
  }

  makePost<T>(url: string, body: any, authenticated: boolean): Observable<T> {
    return this.makePostOrPut(url, body, authenticated, "POST")
  }

  makePut<T>(url: string, body: any, authenticated: boolean): Observable<T> {
    return this.makePostOrPut(url, body, authenticated, "PUT")
  }

  makePostOrPut<T>(url: string, body: any, authenticated: boolean, method: string): Observable<T> {
    let headers = {}

    if (authenticated) {
      headers = this.getAuthorizationHeaders()
    }

    if (method === "POST") {
      console.log('POST')
      return this.http.post<T>(url, body, {
        headers: headers
      }).pipe(
        catchError((err) => {
          return this.handleError(err)
        })
      )
    } else if (method === "PUT") {
      return this.http.put<T>(url, body, {
        headers: headers
      }).pipe(
        catchError((err) => {
          return this.handleError(err)
        })
      )
    } else if (method === "PATCH") {
      return this.http.patch<T>(url, body, {
        headers: headers
      }).pipe(
        catchError((err) => {
          return this.handleError(err)
        })
      )
    } else if (method === "DELETE") {
      return this.http.delete<T>(url, {
        headers: headers
      }).pipe(
        catchError((err) => {
          return this.handleError(err)
        })
      )
    }
  }

  getTml(): Observable<Tml> {
    const url = this.getUrl("users/self/tml")
    return this.makeAuthenticatedGet<Tml>(url)
  }

  getTmlLink(): Observable<Tml> {
    const url = this.getUrl("users/self/tmllink")
    return this.makeAuthenticatedGet<Tml>(url)
  }

  setTmlToken(token: string) {
    const body: Tml = {
      tmlToken: token
    }
    const url = this.getUrl("users/self/tml/token")
    return this.makePut(url, body, true)
  }

  deleteTmlToken(userId: string) {
    const url = this.getUrl(`users/${userId}/tml/token`)
    return this.makeAuthenticatedDelete(url)
  }

  getVouchers(): Observable<any> {
    const url = this.getUrl("vouchers/my")
    return this.makeAuthenticatedGet<any>(url)
  }

  createVoucher(voucher: IVoucher) {
    const url = this.getUrl("vouchers")
    return this.makeAuthenticatedPost(url, voucher)
  }

  login(email: string, password: string): Observable<TokenResponse> {
    const url = this.getUrl("auth/login")
    return this.makeUnauthenticatedPost<TokenResponse>(url, {email, password})
  }

  getUsers(): Observable<User[]> {
    const url = this.getUrl("users")
    return this.makeAuthenticatedGet<User[]>(url)
  }

  getConfigs(): Observable<ITmlConfig[]> {
    const url = this.getUrl("tmlconfigs")
    return this.makeAuthenticatedGet<ITmlConfig[]>(url)
  }

  getWorkers(): Observable<ITmlWorker[]> {
    const url = this.getUrl("tmlworkers")
    return this.makeAuthenticatedGet<ITmlWorker[]>(url)
  }

  createSome(): Observable<any> {
    const url = this.getUrl("tmlworkers/createSome")
    return this.makeAuthenticatedGet<any>(url)
  }

  getTicketLinks(): Observable<ITicketLink[]> {
    const url = this.getUrl("ticketlinks")
    return this.makeAuthenticatedGet<ITicketLink[]>(url)
  }

  createMissingLinks(): Observable<{message: string}> {
    const url = this.getUrl("ticketlinks/create-missing")
    return this.makeAuthenticatedPost(url, {})
  }

  async deleteTicketLink(uuid: string): Promise<boolean> {
    const url = this.getUrl(`ticketlinks/${uuid}`)
    try {
      const result = await this.makeAuthenticatedDelete<any>(url).toPromise()
      return result && result.success
    } catch (e) {
      console.log(e)
    }
    return false
  }

  createTicketLink(body: ICreateTicketLinkDTO): Observable<ITicketLink> {
    const url = this.getUrl("ticketlinks")
    return this.makeAuthenticatedPost(url, body)
  }

  getUserById(userId: string): Observable<User> {
    const url = this.getUrl(`users/${userId}`)
    return this.makeAuthenticatedGet<User>(url)
  }

  confirmVoucher(voucher: IVoucher): Observable<IVoucher> {
    const url = this.getUrl(`vouchers/${voucher.uuid}/confirm`)
    return this.makeAuthenticatedPost<IVoucher>(url, {})
  }

  assignConfigToWorker(worker: ITmlWorker, config: ITmlConfig): Observable<ITmlWorker> {
    const url = this.getUrl(`tmlworkers/${worker.uuid}/config`)
    return this.makeAuthenticatedPost<ITmlWorker>(url, {
      configUuid: config ? config.uuid : null
    })
  }

  assignConfigToWorkers(workerIds: string[], config: ITmlConfig): Observable<ITmlWorker> {
    const url = this.getUrl(`tmlworkers/assignConfig`)
    return this.makeAuthenticatedPost<ITmlWorker>(url, {
      configUuid: config ? config.uuid : null,
      workerIds: workerIds
    })
  }

  getGoLog(workerId: string): Observable<any> {
    const url = this.getUrl(`tmlworkers/${workerId}/go-log`)
    return this.makeAuthenticatedGet<ITmlWorker>(url)
  }

  deployConfigsToAllWorkers(): Observable<any> {
    const url = this.getUrl(`tmlworkers/deployConfigsToAllWorkers`)
    return this.makeAuthenticatedPost(url, {})
  }

  assignAllConfigs(): Observable<any> {
    const url = this.getUrl(`tmlworkers/assignAllConfigs`)
    return this.makeAuthenticatedPost(url, {})
  }

  startTool(worker: ITmlWorker): Observable<any> {
    const url = this.getUrl(`tmlworkers/${worker.uuid}/startTool`)
    return this.makeAuthenticatedPost<any>(url, {})
  }

  startToolAll(): Observable<any> {
    const url = this.getUrl(`tmlworkers/startToolAll`)
    return this.makeAuthenticatedPost<any>(url, {})
  }

  stopToolAll(): Observable<any> {
    const url = this.getUrl(`tmlworkers/stopToolAll`)
    return this.makeAuthenticatedPost<any>(url, {})
  }

  deleteOffline(): Observable<any> {
    const url = this.getUrl(`tmlworkers/deleteOffline`)
    return this.makeAuthenticatedPost<any>(url, {})
  }

  clearCredentials(): Observable<any> {
    const url = this.getUrl(`tmlworkers/clearCredentials`)
    return this.makeAuthenticatedPost<any>(url, {})
  }

  setCredentials(): Observable<any> {
    const url = this.getUrl(`tmlworkers/setCredentials`)
    return this.makeAuthenticatedPost<any>(url, {})
  }

  stopTool(worker: ITmlWorker): Observable<any> {
    const url = this.getUrl(`tmlworkers/${worker.uuid}/stopTool`)
    return this.makeAuthenticatedPost<any>(url, {})
  }

  invalidateVoucher(voucher: IVoucher): Observable<IVoucher> {
    const url = this.getUrl(`vouchers/${voucher.uuid}/invalidate`)
    return this.makeAuthenticatedPost<IVoucher>(url, {})
  }

  getWorkerById(uuid: string): Observable<ITmlWorker> {
    const url = this.getUrl("tmlworkers/" + uuid)
    return this.makeAuthenticatedGet<ITmlWorker>(url)
  }

  getWorkerLog(workerUuid: string): Observable<any> {
    const url = this.getUrl("tmlworkers/" + workerUuid + "/log")
    return this.makeAuthenticatedGet(url)
  }

  getWorkerEvents(uuid: string, type?: string): Observable<ITmlDataEvent[]> {
    let urlString = "tmlworkers/" + uuid + "/events"

    if (type) {
      urlString += "/" + type
    }
    const url = this.getUrl(urlString)
    return this.makeAuthenticatedGet<ITmlDataEvent[]>(url)
  }

  getAllRedirectEvents(): Observable<ITmlDataEvent[]> {
    const url = this.getUrl("tmldataevents/redirect")
    return this.makeAuthenticatedGet<ITmlDataEvent[]>(url)
  }

  getAllTokenEvents(): Observable<ITmlDataEvent[]> {
    const url = this.getUrl("tmldataevents/token")
    return this.makeAuthenticatedGet<ITmlDataEvent[]>(url)
  }

  getAvailableRedirectEvents(): Observable<ITmlDataEvent[]> {
    const url = this.getUrl("tmldataevents/available")
    return this.makeAuthenticatedGet<ITmlDataEvent[]>(url)
  }

  getAllLogs() {
    const url = this.getUrl("logs/all")
    return this.makeAuthenticatedGet<any>(url)
  }

  getSaleSettings() {
    const url = this.getUrl(`tmlconfigs/sale-settings`)
    return this.makeAuthenticatedGet<TmlSaleSettingsResponse>(url)
  }

  getCaptchaKey(posId: string, eventId: string) {
    const url = this.getUrl(`tmlconfigs/captcha-key?posId=${posId}&eventId=${eventId}`)
    return this.makeAuthenticatedGet<{ captchaKey?: string, message: string }>(url)
  }

  patchAllConfigs(saleSettings: Partial<TmlSaleSettings>) {
    const url = this.getUrl(`tmlconfigs/all`)
    return this.makeAuthenticatedPatch<TmlSaleSettingsResponse>(url, saleSettings)
  }

  createUser(email: string, password: string, position: number): Observable<any> {
    const url = this.getUrl(`users`)
    return this.makeAuthenticatedPost<any>(url, {
      position: position,
      email: email,
      password: password
    } as ICreateUserDTO)
  }
}
