<div>
  <h2 class="tmlColor">
    Richte deinen Account ein
  </h2>
  <div>
    <div *ngIf="tml$ | async as tml; else loading">
      <div *ngIf="!tml || !tml.customHash || tml.customHash === '' || !tml.frontofficeHash || tml.frontofficeHash === ''">

        <div class="row">
          <div class="col-md-12">
            <h4>WICHTIG: Benutze Google Chrome</h4>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <h4>1. Gehe auf <a href="https://my.tomorrowland.com" target="_blank">my.tomorrowland.com</a>
              und logge dich mit deinen Tomorrowland Zugangsdaten ein.</h4>
            <p>Du solltest dann Folgendes sehen:</p>
            <img src="/assets/step-01.png" class="img-fluid"/>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <h4>2. Mache einen Rechtsklick irgendwo auf der Seite und wähle "Untersuchen" (Mac) oder "Element
              untersuchen" (Windows)</h4>
            <p>Du solltest dann Folgendes sehen:</p>
            <img src="/assets/step-02.png" class="img-fluid"/>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <h4>3. Wähle den Punkt "Console"/"Konsole" aus</h4>
            <img src="/assets/step-03.png" class="img-fluid"/>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <h4>4. Kopiere das folgende Skript</h4>
            <p>Du kannst entweder alles markieren und kopieren oder auf den "Kopieren" Button klicken. Wenn du die 1.
              Variante (markieren & kopieren) wählst, stelle sicher, dass du alles aus dem Feld markiert hast.</p>
            <textarea rows="6" class="form-control" id="userJson" [ngModel]="script" readonly="true"></textarea>
            <a class="mt-2 btn btn-primary" (click)="copyScript()">Skript kopieren</a>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <h4>5. Füge das Kopierte in das Eingabefeld der Konsole ein und drücke "Enter"</h4>
            <img src="/assets/step-04.png" class="img-fluid"/>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <h4>6. Falls eine Meldung mit "Dein Account wurde erfolgreich eingerichtet." angezeigt wird, klicke
              folgenden Button. Ansonsten fahre mit Schritt 7 fort.</h4>
            <button class="btn btn-outline-primary" (click)="reload()">Fertigstellen</button>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <h4>7. Klicke in das Eingabefeld rechts neben dem '>', gib "allow pasting" (ohne die "") ein und drücke
              "Enter"</h4>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <h4>8. Wiederhole die Anleitung ab Schritt 4.</h4>
          </div>
        </div>




      </div>

      <div *ngIf="tml.customHash && tml.frontofficeHash">
        <div class="card mb-2">
          <div class="card-body">
            <div class="container">
              <div class="row">
                <h4>Du hast deine Daten erfolgreich gepflegt.</h4></div>
              <div class="row">
                <fa-icon class="tmlColor centerIcon text-center mb-2" icon="check-circle" size="6x"></fa-icon>
              </div>
              <div class="row">
                <button class="btn btn-outline-primary" routerLink="/ticketsale">Zum Ticketverkauf</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-alerts #alerts></app-alerts>

    <ng-template #loading>
      <div class="text-center">
        <fa-icon class="tmlColor" icon="circle-notch" [spin]="true" size="6x"></fa-icon>
      </div>
    </ng-template>


  </div>
</div>
