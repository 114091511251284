import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core"
import { IVoucher } from "@mb-tml/shared"
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from "@angular/forms"
import { ApiService } from "../api.service"

@Component({
  selector: "app-create-voucher-form",
  templateUrl: "./create-voucher-form.component.html",
  styleUrls: ["./create-voucher-form.component.scss"]
})
export class CreateVoucherFormComponent implements OnInit {

  private voucher: IVoucher
  form: UntypedFormGroup

  @Output() created = new EventEmitter<boolean>()
  @Input() afterCreate

  constructor(private fb: UntypedFormBuilder, private apiService: ApiService) { }

  ngOnInit() {
    this.form = this.fb.group({
      code: ["", [Validators.required]],
      value: ["", [Validators.required, Validators.min(1)]]
    })
  }

  addVoucher() {
    this.createVoucher()
  }

  createVoucher() {
    const voucher: IVoucher = {
      code: this.form.value.code,
      value: this.form.value.value,
    }

    this.apiService.createVoucher(voucher).subscribe((value) => {
      this.form.reset()
      console.log(value)
      this.created.emit(true)
      this.afterCreate("voucherCreated")
    })
  }

}
