import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(public auth: AuthService, public router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise((resolve, reject) => {
            const loggedIn = route.data.loggedIn as boolean;
            console.log('needs to be logged in: ' + loggedIn);
            if (loggedIn === true) {
                if (!this.auth.isLoggedIn()) {
                    this.router.navigate(['login']);
                    resolve(false);
                } else {
                    resolve(true);
                }
            } else {
                console.log(state.url);
                if (this.auth.isLoggedIn()) {
                    this.router.navigate(['ticketsale']);
                    resolve(false);
                } else {
                    resolve(true);
                }

            }
        })

    }
}
