<app-alerts #alerts></app-alerts>

<form [formGroup]="form" (submit)="login()">
  <div class="form-group" [ngClass]="{
    'has-danger': form.controls.username.invalid && form.controls.username.dirty,
    'has-success': form.controls.username.valid && form.controls.username.dirty
  }">
    <label for="username">Benutzername</label>
    <div class="input-group">
      <input type="text" id="username" class="form-control" name="username" formControlName="username">
    </div>
  </div>
  <div class="form-group">
    <label for="password">Passwort</label>
    <div class="input-group">
      <input id="password" class="form-control" name="password" type="password" formControlName="password">
    </div>
  </div>
  <div class="form-group">
      <button type="submit" [disabled] = "!form.valid" class="btn btn-success">Login</button>
  </div>
</form>

