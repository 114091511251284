<form [formGroup]="form" (submit)="addVoucher()">

    <div class="form-group" [ngClass]="{
      'has-danger': form.controls.code.invalid && form.controls.code.dirty,
      'has-success': form.controls.code.valid && form.controls.code.dirty
    }">
      <label for="code">Code</label>
      <div class="input-group">
        <input id="code" class="form-control" name="code" formControlName="code" placeholder="XXXX-XXXXXX-XXXXX">
      </div>
    </div>
    <div class="form-group">
      <label for="value">Value</label>
      <div class="input-group">
        <input id="value" class="form-control" name="value" type="number" formControlName="value">
      </div>
    </div>
    <div class="form-group">
        <button type="submit" [disabled] = "!form.valid" class="btn btn-success">Add Voucher</button>
    </div>
  </form>

