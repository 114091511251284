import { Component, OnInit, ViewChild } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { ApiService } from "../api.service"
import { AlertsComponent, AlertType } from "../shared/alerts/alerts.component"
import {Clipboard} from '@angular/cdk/clipboard';

import * as pako from "pako"
import { Observable } from "rxjs"
import { Tml } from "@mb-tml/shared"

@Component({
    selector: "app-tml-token",
    templateUrl: "./tml-token.component.html",
    styleUrls: ["./tml-token.component.scss"]
})
export class TmlTokenComponent implements OnInit {

    form: UntypedFormGroup

    @ViewChild("alerts") alerts: AlertsComponent

    tml$: Observable<any>

    script

    constructor(private fb: UntypedFormBuilder, private apiService: ApiService, private clipboard: Clipboard) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            userJson: ["", [Validators.required]]
        })

        this.tml$ = this.apiService.getTml()

        const token = this.apiService.getToken()
        this.script = this.getScript(token)

    }

    copyScript() {
      this.clipboard.copy(this.script)
    }

    storeToken() {

        this.alerts.clear()

        let userJson

        try {
            userJson = JSON.parse(this.form.value.userJson)
        } catch (e) {
            // ...
        }

        if (!userJson) {
            this.alerts.addAlert("Die eingegebenen Daten sind ungültig.", AlertType.ERROR)
            return
        }

        this.apiService.setTmlToken(userJson).subscribe((data) => {
            this.alerts.addAlert("Deine Daten wurden erfolgreich gespeichert.", AlertType.SUCCESS)
            this.tml$ = this.apiService.getTml()
        })

    }

    tokenIsValid(token: string) {
        try {
            const r = JSON.parse(token)
            if (r) {
                return true
            }
        } catch (e) {
            return false
        }
    }

    getScript(token: string) {
        const apiUrl = location.protocol + "//" + location.host + "/api/users/self/tml/account"
        const script = `
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.assets.tomorrowland.com/scripts/tml-sso-11.1.0.min.js';
    document.head.appendChild(script);

    setTimeout(() => {
      new window.TML.TMLUser().getUser().then(user => {
          fetch('${apiUrl}', {
              method: 'PUT',
              body: JSON.stringify(user),
              headers: {
                  'Authorization': 'Bearer ${token}',
                  'Content-type': 'application/json; charset=UTF-8'
              }
          })
          .then(res => {
              res.json().then((data) => {
                  if (data.text) {
                      alert(data.text)
                  } else {
                      alert('Es ist ein Fehler aufgetreten.')
                  }
              }).catch((e) => {
                  alert('Es ist ein Fehler aufgetreten.')
                  console.log(e)
              });

          })
          .catch(e => {
              alert('Es ist ein Fehler aufgetreten.')
              console.log(e)
          })
      })
    }, 2000);
    `

        return script.trim()
    }

    reload() {
        window.location.reload()
    }

}
