import { Injectable } from '@angular/core'
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http"
import {LoadingService} from "./loading.service"
import {catchError, map} from "rxjs/operators"
import {Observable, throwError} from "rxjs"

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(
    private _loading: LoadingService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loading.setLoading(true, request.url)
    return next.handle(request)
      .pipe(catchError((err) => {
        this._loading.setLoading(false, request.url)
        return throwError(() => err)
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this._loading.setLoading(false, request.url)
        }
        return evt
      }))
  }
}
