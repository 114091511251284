import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {ApiError} from './api-error.interface';
import {Observable, throwError, observable, of} from 'rxjs';

import {map} from 'rxjs/operators';
import {TokenService} from './token.service';
import {TokenResponse} from "@mb-tml/shared"
import * as jwt_decode from "jwt-decode";

@Injectable()
export class AuthService {

  loggedIn = this.isLoggedIn();
  admin = this.isLoggedIn() && this.isAdmin()

  constructor(private apiService: ApiService, private tokenService: TokenService) {
  }

  storeToken(token: string) {
    localStorage.setItem('token', token);
    this.loggedIn = true;
    this.admin = this.isAdmin()
  }

  isLoggedIn(): boolean {
    const loggedIn = localStorage.getItem('token') !== null;
    this.loggedIn = loggedIn;
    return loggedIn;
  }

  isAdmin(): boolean {
    const token = localStorage.getItem('token');
    if (!token) {
      return false
    }

    try {
      const decoded = jwt_decode(token);
      return decoded.admin
    } catch (Error) {
      return false;
    }
  }

  deleteToken() {
    localStorage.removeItem('token');
    this.loggedIn = false;
    this.admin = false
  }

  getToken(): string {
    return this.tokenService.getToken();
  }

  login(email: string, password: string) {



    return this.apiService.login(email, password).pipe(map((data) => {
      this.storeToken(data.token);
      return data
    }));
  }

  logout() {
    this.deleteToken();
  }
}
