<div class="position-relative overflow-hidden p-3 p-md-5 text-center bg-light" *ngIf="!tml && !tmlLink">
  <div class="col-md-5 p-lg-5 mx-auto my-5">
    <h1 class="display-4 font-weight-normal">Lade..</h1>
  </div>
  <div class="product-device box-shadow d-none d-md-block"></div>
  <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
</div>

<ng-container *ngIf="tml && tmlLink">

  <div class="position-relative overflow-hidden p-3 p-md-5 text-center bg-light"
       *ngIf="!tml.customHash || !tml.frontofficeHash">
    <div class="col-md-5 p-lg-5 mx-auto my-5">
      <h1 class="display-4 font-weight-normal">Bitte richte deinen Account ein.</h1>
      <a class="btn btn-outline-primary" routerLink="/setup">Jetzt einrichten</a>
    </div>
    <div class="product-device box-shadow d-none d-md-block"></div>
    <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
  </div>

  <div class="position-relative overflow-hidden p-3 p-md-5 text-center bg-light"
       *ngIf="tml && tml.customHash && tml.frontofficeHash && tmlLink.tmlLink === ''">
    <div class="col-md-5 p-lg-5 mx-auto my-5">
      <h1 class="display-4 font-weight-normal" *ngIf="tml?.customHashDecrypted?.consumer?.firstName">
        Hallo {{ tml?.customHashDecrypted?.consumer?.firstName }} 👋</h1>
      <h2>Bitte warte auf deinen Link. Die Seite aktualisiert sich automatisch.</h2>
      <p class="lead font-weight-normal">Letzte Aktualisierung: {{lastRefresh}}</p>
      <p class="lead font-weight-normal" *ngIf="nextRefresh">Nächste Aktualisierung: {{nextRefresh}}</p>
    </div>
    <div class="product-device box-shadow d-none d-md-block"></div>
    <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
  </div>


  <div class="p-3 p-md-5 text-center tml-link-available" *ngIf="tmlLink && tmlLink.tmlLink !== ''">
    <div class="col-md-5 p-lg-5 mx-auto my-5">
      <h1 class="display-4 font-weight-normal">Tada! 🎉</h1>
      <h2>Dein Link ist da. Du kannst nun zum Ticket Shop. Beachte, dass du nur 5 Minuten Zeit hast, um deine Bestellung
        abzuschließen.</h2>
      <div class="pt-4">
        <a class="btn btn-outline-primary" target="_blank" href="{{tmlLink.tmlLink}}">Zum Ticket Shop</a>
      </div>
    </div>
    <div class="product-device box-shadow d-none d-md-block"></div>
    <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
  </div>

</ng-container>
