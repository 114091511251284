<div class="position-relative overflow-hidden p-3 p-md-5 text-center bg-light">
    <div class="col-md-5 p-lg-5 mx-auto my-5">
      <h1 class="display-4 font-weight-normal">ITYTT</h1>
      <p class="lead font-weight-normal"><strong>I </strong> <strong>t</strong>ake <strong>y</strong>ou <strong>t</strong>o <strong>T</strong>omorrowland</p>
        <a class="btn btn-outline-success" routerLink="/login" *ngIf="!authService.isLoggedIn()">Login</a>
        <a class="btn btn-outline-success" routerLink="/ticketsale" *ngIf="authService.isLoggedIn()">Zum Ticket Verkauf</a>
    </div>
    <div class="product-device box-shadow d-none d-md-block"></div>
    <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
  </div>

