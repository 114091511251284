import { Component, OnInit, Input } from '@angular/core';

import { ApiError } from '../../api-error.interface';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  constructor() { }

  @Input()
  public alerts: Array<IAlert> = [];

  private id = 0;

  ngOnInit() {}

  addError(apiError: ApiError) {
    this.addErrorMessage(apiError.message);
  }

  addErrorMessage(msg: string) {
    this.addAlert(msg, AlertType.ERROR);
  }

  addAlert(message: string, type: AlertType = AlertType.WARNING) {
    this.alerts.push({
      id: this.id,
      message: message,
      type: type,
    });
    this.id++;
  }

  clear() {
    this.alerts = [];
    this.id = 0;
  }

  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }

}

export enum AlertType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'danger'
}

export interface IAlert {
  id?: number;
  type?: string;
  message?: string;
}
