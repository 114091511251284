<div class="p-3 px-md-4 border-bottom">
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">ITYTT</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse itytt-nav">
        <div class="navbar-nav">
          <a class="nav-link" routerLink="/home" routerLinkActive="link-active">Home</a>
          <a class="nav-link" routerLink="/ticketsale" routerLinkActive="link-active" *ngIf="authService.isLoggedIn()">Ticket
            Verkauf</a>
          <a class="nav-link" routerLink="/setup" routerLinkActive="link-active" *ngIf="authService.isLoggedIn()">Einrichtung</a>

        </div>
        <div class="d-flex">
          <span class="navbar-text p-2" *ngIf="loading">
            <fa-icon class="centerIcon text-center" icon="sync" size="lg" animation="spin"></fa-icon>
          </span>
          <a class="p-2 btn btn-outline-danger" *ngIf="authService.isLoggedIn()" (click)="logout()">Logout</a>
          <a class="p-2 btn btn-outline-primary" style="margin-left: 10px;" routerLink="/admin" *ngIf="authService.isAdmin()">Admin</a>
        </div>
      </div>
    </div>
  </nav>
</div>

<div>
  <router-outlet></router-outlet>
</div>
